import React from 'react';

function About() {
  return (
    <div>
      
    </div>
  );
}

export default About;
